var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 mr-0" },
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.DeleteManual },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading && _vm.data != null
        ? _c("v-data-table", {
            attrs: {
              search: _vm.nameSearchable,
              items: _vm.data,
              headers: _vm.dataHeaders,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            width: "auto",
                            height: "auto",
                            color: "py-3",
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v(" Manual ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-6" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          clearable: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.nameSearchable,
                                          callback: function ($$v) {
                                            _vm.nameSearchable = $$v
                                          },
                                          expression: "nameSearchable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.currentRole == "operation"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.redirectToManualCreate },
                                    },
                                    [_vm._v(" New ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.action",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectToManualUpdate(item.id)
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(item.id)
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          on: {
                            click: function ($event) {
                              return _vm.redirectToManualDetail(item.key)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3660818538
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }